import { default as cryptoticket_45invalidLBNWF4UaODMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/cryptoticket-invalid.vue?macro=true";
import { default as _91_slug_93dPFvTnvHXDMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/geraete/[_slug].vue?macro=true";
import { default as indexq7u4esximxMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/index.vue?macro=true";
import { default as maintenancewwI7rtQ5eqMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/maintenance.vue?macro=true";
import { default as routes_45infoLX3EEjMDtHMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/routes-info.vue?macro=true";
import { default as bestaetigung3KPASdQEV3Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/bestaetigung.vue?macro=true";
import { default as fehlerKnI7qDsfKGMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/fehler.vue?macro=true";
import { default as persoenliche_45datensoKkaekHf1Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/kundenformular/persoenliche-daten.vue?macro=true";
import { default as kundenformularyWtYeYVC4OMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/kundenformular.vue?macro=true";
import { default as warenkorb_45virtuelles_45regalq56akO67ZhMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/warenkorb-virtuelles-regal.vue?macro=true";
import { default as warenkorbzqksRUdYoLMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/warenkorb.vue?macro=true";
import { default as zusammenfassungiaeseDf6s1Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/zusammenfassung.vue?macro=true";
import { default as _91_46_46_46config_9389EWg6vIn1Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/suche/[...config].vue?macro=true";
import { default as transform_45paginationcwfAcPFlQdMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/suche/transform-pagination.ts?macro=true";
export default [
  {
    name: cryptoticket_45invalidLBNWF4UaODMeta?.name ?? "cryptoticket-invalid",
    path: cryptoticket_45invalidLBNWF4UaODMeta?.path ?? "/cryptoticket-invalid",
    meta: cryptoticket_45invalidLBNWF4UaODMeta || {},
    alias: cryptoticket_45invalidLBNWF4UaODMeta?.alias || [],
    redirect: cryptoticket_45invalidLBNWF4UaODMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/cryptoticket-invalid.vue").then(m => m.default || m)
  },
  {
    name: _91_slug_93dPFvTnvHXDMeta?.name ?? "geraete-_slug",
    path: _91_slug_93dPFvTnvHXDMeta?.path ?? "/geraete/:_slug()",
    meta: _91_slug_93dPFvTnvHXDMeta || {},
    alias: _91_slug_93dPFvTnvHXDMeta?.alias || [],
    redirect: _91_slug_93dPFvTnvHXDMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/geraete/[_slug].vue").then(m => m.default || m)
  },
  {
    name: indexq7u4esximxMeta?.name ?? "index",
    path: indexq7u4esximxMeta?.path ?? "/",
    meta: indexq7u4esximxMeta || {},
    alias: indexq7u4esximxMeta?.alias || [],
    redirect: indexq7u4esximxMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancewwI7rtQ5eqMeta?.name ?? "maintenance",
    path: maintenancewwI7rtQ5eqMeta?.path ?? "/maintenance",
    meta: maintenancewwI7rtQ5eqMeta || {},
    alias: maintenancewwI7rtQ5eqMeta?.alias || [],
    redirect: maintenancewwI7rtQ5eqMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: routes_45infoLX3EEjMDtHMeta?.name ?? "routes-info",
    path: routes_45infoLX3EEjMDtHMeta?.path ?? "/routes-info",
    meta: routes_45infoLX3EEjMDtHMeta || {},
    alias: routes_45infoLX3EEjMDtHMeta?.alias || [],
    redirect: routes_45infoLX3EEjMDtHMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/routes-info.vue").then(m => m.default || m)
  },
  {
    name: bestaetigung3KPASdQEV3Meta?.name ?? "shop-bestaetigung",
    path: bestaetigung3KPASdQEV3Meta?.path ?? "/shop/bestaetigung",
    meta: bestaetigung3KPASdQEV3Meta || {},
    alias: bestaetigung3KPASdQEV3Meta?.alias || [],
    redirect: bestaetigung3KPASdQEV3Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: fehlerKnI7qDsfKGMeta?.name ?? "shop-fehler",
    path: fehlerKnI7qDsfKGMeta?.path ?? "/shop/fehler",
    meta: fehlerKnI7qDsfKGMeta || {},
    alias: fehlerKnI7qDsfKGMeta?.alias || [],
    redirect: fehlerKnI7qDsfKGMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/fehler.vue").then(m => m.default || m)
  },
  {
    name: kundenformularyWtYeYVC4OMeta?.name ?? "shop-kundenformular",
    path: kundenformularyWtYeYVC4OMeta?.path ?? "/shop/kundenformular",
    meta: kundenformularyWtYeYVC4OMeta || {},
    alias: kundenformularyWtYeYVC4OMeta?.alias || [],
    redirect: kundenformularyWtYeYVC4OMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/kundenformular.vue").then(m => m.default || m),
    children: [
  {
    name: persoenliche_45datensoKkaekHf1Meta?.name ?? "shop-kundenformular-persoenliche-daten",
    path: persoenliche_45datensoKkaekHf1Meta?.path ?? "persoenliche-daten",
    meta: persoenliche_45datensoKkaekHf1Meta || {},
    alias: persoenliche_45datensoKkaekHf1Meta?.alias || [],
    redirect: persoenliche_45datensoKkaekHf1Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/kundenformular/persoenliche-daten.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warenkorb_45virtuelles_45regalq56akO67ZhMeta?.name ?? "shop-warenkorb-virtuelles-regal",
    path: warenkorb_45virtuelles_45regalq56akO67ZhMeta?.path ?? "/shop/warenkorb-virtuelles-regal",
    meta: warenkorb_45virtuelles_45regalq56akO67ZhMeta || {},
    alias: warenkorb_45virtuelles_45regalq56akO67ZhMeta?.alias || [],
    redirect: warenkorb_45virtuelles_45regalq56akO67ZhMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/warenkorb-virtuelles-regal.vue").then(m => m.default || m)
  },
  {
    name: warenkorbzqksRUdYoLMeta?.name ?? "shop-warenkorb",
    path: warenkorbzqksRUdYoLMeta?.path ?? "/shop/warenkorb",
    meta: warenkorbzqksRUdYoLMeta || {},
    alias: warenkorbzqksRUdYoLMeta?.alias || [],
    redirect: warenkorbzqksRUdYoLMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/warenkorb.vue").then(m => m.default || m)
  },
  {
    name: zusammenfassungiaeseDf6s1Meta?.name ?? "shop-zusammenfassung",
    path: zusammenfassungiaeseDf6s1Meta?.path ?? "/shop/zusammenfassung",
    meta: zusammenfassungiaeseDf6s1Meta || {},
    alias: zusammenfassungiaeseDf6s1Meta?.alias || [],
    redirect: zusammenfassungiaeseDf6s1Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/shop/zusammenfassung.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46config_9389EWg6vIn1Meta?.name ?? "suche-config",
    path: _91_46_46_46config_9389EWg6vIn1Meta?.path ?? "/suche/:config(.*)*",
    meta: _91_46_46_46config_9389EWg6vIn1Meta || {},
    alias: _91_46_46_46config_9389EWg6vIn1Meta?.alias || [],
    redirect: _91_46_46_46config_9389EWg6vIn1Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/suche/[...config].vue").then(m => m.default || m)
  },
  {
    name: transform_45paginationcwfAcPFlQdMeta?.name ?? "suche-transform-pagination",
    path: transform_45paginationcwfAcPFlQdMeta?.path ?? "/suche/transform-pagination",
    meta: transform_45paginationcwfAcPFlQdMeta || {},
    alias: transform_45paginationcwfAcPFlQdMeta?.alias || [],
    redirect: transform_45paginationcwfAcPFlQdMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20250106073251/pages/suche/transform-pagination.ts").then(m => m.default || m)
  }
]